.AudioPlayer-Scrubber {
  position: relative;
  display: grid;
}

.AudioPlayer-Scrubber__range {
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  opacity: 0;
}

.AudioPlayer-Scrubber__progress {
  grid-column: 1/-1;
  grid-row: 1/-1;
  max-width: 100%;

  display: flex;
  align-items: center;

  user-select: none;
  overflow: hidden;
  pointer-events: none;
}

.AudioPlayer-Scrubber__head {
  grid-column: 1/-1;
  grid-row: 1/-1;
  align-self: start;
  justify-self: start;

  display: flex;

  user-select: none;
  overflow: hidden;
  pointer-events: none;

  position: relative;
  transform: translateX(calc((var(--head-position) * 100%)));
  overflow: visible;
}

.AudioPlayer-Scrubber__dash.head {
  opacity: 0;
}
