* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

details > summary {
  list-style: none;
  cursor: pointer;
}
details > summary::marker,
details > summary::-webkit-details-marker {
  display: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

button:focus {
  outline: 0;
}
