.Text {
}

.Text p {
  margin-bottom: var(--line-height);
}

.Text h2 {
  text-align: center;
  margin-bottom: var(--line-height);
}

.Text *:last-child {
  margin-bottom: 0;
}
