.Site-Footer {
  margin-top: var(--line-height);
}

.Site-Footer dl {
  margin-top: var(--line-height);
}

.Site-Footer dd {
  margin-bottom: var(--line-height);
}

.Site-Footer a {
  position: relative;
}

.Site-Footer a:hover::after {
  position: absolute;
  top: 0.5em;
  height: 1em;
  left: 0;
  right: 0;
  content: "-------------------------------------------------------";
  overflow: hidden;
}
