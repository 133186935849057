.Report {
}

.Report--debug::after {
  content: "Forecast: " attr(data-report-forecast-value) "\AWeather category: "attr(data-report-weather-value);
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 18px;
  background-color: pink;
  padding: 0.3em 0.5em;
  border-radius: 0.3em;
  width: max-content;
  white-space: pre-wrap;
}
