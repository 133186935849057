@font-face {
  font-family: "DotMatrix";
  src: url("../fonts/DotMatrix.woff") format("woff"),
    url("../fonts/DotMatrix.woff2") format("woff2");
  font-display: block;
}

:root {
  --font-family: "DotMatrix", sans-serif;
  --font-size: 6.75vw;
  --line-height: 6.75vw;
}

@media screen and (min-width: 768px) {
  :root {
    --font-size: 5rem;
    --line-height: 5rem;
  }
}

html {
  font-size: 62.5%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  margin-top: var(--line-height);
  margin-bottom: var(--line-height);
}
