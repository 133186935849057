@import "shared/reset.css";
@import "shared/typography.css";

body {
  --background-color: #95a1aa;
  background-color: var(--background-color);

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;

  padding: calc(var(--line-height) / 2);
}

@media screen and (min-width: 768px) {
  body {
    padding: var(--line-height);
  }
}
