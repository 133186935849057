.SvgText-Character {
  display: inline;
  position: relative;
}

.SvgText-Character__character {
  color: var(--background-color);
}

.SvgText-Character svg {
  position: absolute;
  bottom: 0.2em;
  left: 0;

  height: 1em;
  transform: scale(1, -1);
  overflow: visible;
}

.SvgText-Character path {
  transform-box: fill-box;
  transform-origin: center;
}
