.AudioPlayer {
}

.AudioPlayer__audio {
  display: none;
}

.AudioPlayer__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5ch;
}

.AudioPlayer-Scrubber {
  width: 100%;
}

.AudioPlayer button {
  display: flex;
  cursor: pointer;
}

.AudioPlayer:not(.AudioPlayer--playing) .AudioPlayer__icon--pause {
  display: none;
}

.AudioPlayer--playing .AudioPlayer__icon--play {
  display: none;
}
